.paragraph-block,
.faq-paragraph-block,
.paragraph-svg-block {
  .paragraph-title {
    color: $grey-d2;
  }
  h2 {
    @include fluid-type(414px, 1920px, 42px, 54px);
    line-height: calc(42px + (48 - 42) * ((100vw - 414px) / (1920 - 414)));
    font-weight: 600;
    @media (min-width: 1920px) {
      font-size: 54px;
    }
    @media (max-width: 414px) {
      font-size: 42px;
    }
  }
  h3 {
    @include fluid-type(414px, 1920px, 34px, 40px);
    line-height: calc(34px + (40 - 34) * ((100vw - 414px) / (1920 - 414)));
    font-weight: 600;
    @media (min-width: 1920px) {
      font-size: 40px;
    }
    @media (max-width: 414px) {
      font-size: 34px;
    }
  }
  h4 {
    @include fluid-type(414px, 1920px, 20px, 24px);
    line-height: calc(24px + (28 - 24) * ((100vw - 414px) / (1920 - 414)));
    font-weight: 400;
    color: $grey-d3 !important;
    margin-top: 17px;
    margin-bottom: 34px;
    @media (min-width: 1920px) {
      font-size: 24px;
    }
    @media (max-width: 414px) {
      font-size: 20px;
    }
  }
  p {
    a {
      @include fluid-type(414px, 1920px, 18px, 20px);
      font-weight: 600;
      border-bottom: 1px solid $pink;
      color: $grey-d2;
      text-decoration: none;
      line-height: calc(26px + (28 - 26) * ((100vw - 414px) / (1920 - 414)));
    }
  }
  ul {
    li {
      list-style: none;
      @include fluid-type(414px, 1920px, 18px, 20px);
      line-height: calc(22px + (28 - 22) * ((100vw - 414px) / (1920 - 414)));
      margin-bottom: 24px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-color: $pink;
        height: 8px;
        width: 8px;
        left: -19px;
        border-radius: 50%;
        top: 10px;
      }
    }
  }
  ol {
    padding-left: 0;
    li {
      list-style: none;
      margin-bottom: 24px;
      @include fluid-type(414px, 1920px, 18px, 20px);
      line-height: calc(22px + (28 - 22) * ((100vw - 414px) / (1920 - 414)));
      color: $grey-d3 !important;
    }
  }
}

.faq-paragraph-block {
  margin-bottom: 100px;
  &.support-block {
    margin-top: -100px;
    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
    a {
      margin-top: 60px;
    }
  }
  h3 {
    @include line-title;
  }
  a {
    border-bottom: 1px solid $pink;
    color: $grey-d2 !important;
    font-weight: 600 !important;
    margin-bottom: 50px;
    padding-bottom: 6px;
    display: inline-block;
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
