html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 2rem;
  color: $grey;
  min-height: 100vh;
  padding-bottom: 143px;
  position: relative;
  font-family: "futura-pt" !important;
  background-image: linear-gradient(to top, transparent 444px, #fff 0px);
  @include media-breakpoint-down(md) {
    padding-top: 100px;
  }
  &:not(.page-formpage) {
    padding-top: 250px;
  }
  &.no-canvas{
    @include media-breakpoint-down(sm){
      padding-top: 80px;
    }
  }
  &.page-formpage {
    .nav-container {
      display: none;
    }
  }
  &.overflow {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .content {
    background-color: $white;
  }
  &.airserve {
    overflow-x: hidden;
  }
  &.page-standardaspage {
    #myCanvas {
      display: none;
    }
  }
}
.font-caveat {
  font-family: "caveat";
}
img {
  max-width: 100%;
}

main {
  padding-top: 250px;
  @include media-breakpoint-down(md) {
    padding-top: 45vh;
  }
  &.airserve-main {
    padding-top: 0;
    &.standardaspage {
      margin-top: 50px;
    }
    &.airservelandingpage,
    &.airservehomepage {
      margin-top: -110px;
      // overflow-x: hidden;
      @include media-breakpoint-down(lg) {
        margin-top: -180px;
      }
      @include media-breakpoint-down(md) {
        margin-top: 30px;
      }
    }
  }
}

h1 {
  color: $grey-dark;
  font-weight: 700;
  @include fluid-type(414px, 1920px, 28px, 68px);
}

h2 {
  color: $grey-dark;
  font-weight: 500;
  @include fluid-type(414px, 1920px, 26px, 40px);
  line-height: 46px;
  @include media-breakpoint-down(md) {
    line-height: 38px;
  }
}

h3 {
  color: $grey-dark;
  font-weight: 500;
  @include fluid-type(414px, 1920px, 24px, 30px);
  line-height: 42px;
  @include media-breakpoint-down(md) {
    line-height: 34px;
  }
}

blockquote {
  font-size: 2vmax;
  line-height: 2.5vmax;
  color: $grey-dark;
  font-weight: 500;
  font-style: oblique;

  @include media-breakpoint-down(md) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
p {
  a {
    @include fluid-type(414px, 1920px, 14px, 22px);
    line-height: calc(28px + (32 - 28) * ((100vw - 414px) / (1920 - 414)));
    color: $grey-light;
  }
}

.rich-text {
  ul {
    li {
      @include fluid-type(414px, 1920px, 14px, 22px);
      color: $grey-light;
      line-height: 3.2rem;
      @include media-breakpoint-down(md) {
        line-height: 3rem;
      }
      @include media-breakpoint-down(sm) {
        line-height: 2.6rem;
      }
    }
  }
}

.short-description {
  font-size: 1.4rem;
  color: $grey;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 40px;
}
