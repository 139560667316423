.popular-tools-block{
    margin-top: 60px;
    @include media-breakpoint-down(md){
        .row{
            justify-content: center;
            .title{
                font-size: 22px !important;
                text-align: center;
            }
        }
    }
    .title{
        font-size: 24px;
        font-weight: 600;
    }
    .logo-row{
        margin-left: 25% !important;
        @include media-breakpoint-down(md){
            margin-left: 0 !important;
        }
        img{
            min-width: 76px;
            height: auto;
        }
    }
    .text{
        font-family: 'Caveat', cursive;
        font-size: 30px;
        margin-top: 20px;
        @include media-breakpoint-down(md){
            margin-top: -20px;
        }
    }
}