$grid-columns: 16;
$grid-gutter-width: 8px;
$padding: calc(4px + (17 - 4) * ((100vw - 414px) / (1920 - 414)));
div[class*="col-"]{
    padding-left: $padding;
    padding-right: $padding;
    margin-bottom: 30px;
}
@media only screen and (min-width: 1920px) {
    div[class*="col-"]{
        padding-left: 17px;
        padding-right: 17px;
        margin-bottom: 30px;
    }
} 
.clamped{
    max-width: 1920px;
}