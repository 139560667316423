.nav-container {
  height: 400px;
  left: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 4;
  &.standardaspage {
    z-index: 100;
    height: 140px;
  }
  @include media-breakpoint-down(md) {
    height: auto;
  }
  @include media-breakpoint-up(lg){
    &.nav-down, &.nav-up {
      transform: translateY(0);
      transition: transform 0.3s linear;
      .nav-inner-container {
        height: 100px;
        transition: height 0.3s linear;
        .logo {
          svg {
            width: 65px;
            transition: width 0.5s linear;
          }
          span {
            display: none;
          }
        }
      }
    }
  }
  .nav-inner-container {
    background: linear-gradient(202.75deg, $pink-d1 0%, $pink 100%);
    height: 140px;
    transition: height 0.3s linear;
    @include media-breakpoint-down(lg) {
      height: 80px;
    }
    .as-nav {
      position: unset !important;
      height: auto;
    }
    #myCanvas {
      max-height: 100000px;
      @media (max-width: 1200px) {
        max-height: 200px;
      }
    }
    .nav-inner {
      width: 100%;
      z-index: 3;
      margin-bottom: 0 !important;
      .logo-container {
        margin-right: calc(
          15px + (275 - 15) * ((100vw - 798px) / (1920 - 798))
        );
        @include media-breakpoint-down(lg) {
          z-index: 100;
          margin-right: 40px;
          svg {
            width: 46px;
            height: 36px;
          }
        }
        a.logo {
          text-decoration: none;
          align-items: center;
        }
        .logo-text {
          font-size: 30px;
          line-height: 39px;
          color: $white;
          font-weight: 600;
          @include media-breakpoint-down(lg) {
            font-size: 17px;
            line-height: 19px;
          }
        }
      }
      .nav-items {
        width: 100%;
      }
      .sign-up-btn{
        margin-right: calc(
          24px + (145 - 24) * ((100vw - 798px) / (1920 - 798))
        );
        @include media-breakpoint-down(md){
          height: 60px;
        }
        a{
          background-color: transparent;
          border: 1px solid $white;
          border-radius: 6px;
          color: $white;
          @include fluid-type(414px, 1920px, 13px, 20px);
          font-weight: 600;
          height: 45px;
          line-height: 26px;
          padding: 9px 13px 13px 13px;
          white-space: nowrap;
          text-decoration: none;
          transition: background-color .2s linear, border .2s linear;
          @include media-breakpoint-down(md){
            font-size: 21px;
          }
          &:hover{
            background-color: $green;
            border: 1px solid transparent;
            transition: background-color .2s linear;
          }
        }
      }
      ul.menuitems {
        display: flex;
        margin-bottom: 0;
        padding-left: 0;
        @include media-breakpoint-down(md) {
          display: block;
          padding-top: 160px;
          width: 50%;
          margin: 0 auto;
        }
        li.menuitem {
          list-style: none;
          margin-right: calc(
            24px + (50 - 24) * ((100vw - 798px) / (1920 - 798))
          );
          @include media-breakpoint-down(md) {
            margin-right: 0;
          }
          margin-bottom: 0;
          &::before {
            content: none;
          }
          &:nth-child(4) {
            margin-left: auto;
            @include media-breakpoint-down(md) {
              margin-bottom: 56px;
            }
          }
          @include media-breakpoint-down(md) {
            margin-bottom: 32px;
          }
          &.active {
            a {
              position: relative;
              &::after {
                content: "";
                position: absolute;
                background-color: $yellow-l1;
                height: 3px;
                width: 44px;
                left: calc(50% - 22px);
                bottom: -15px;
              }
            }
          }
          a {
            color: $white;
            @include fluid-type(414px, 1920px, 13px, 20px);
            font-weight: 600;
            text-decoration: none;
          }
        }
      }
    }
    .nav {
      background-color: transparent;
      width: 100vw;
      @include media-breakpoint-down(md) {
        height: 0;
        position: unset;
      }
      .navbar-toggler {
        right: 22px;
        position: absolute;
        top: 32px !important;
        width: 50px;
        z-index: 1;
        @include media-breakpoint-down(md) {
          top: 14px !important;
        }
        .navbar-toggler-icon {
          > div {
            background-color: $white !important;
            height: 2px;
            margin-bottom: 4px;
            width: 26px;
            transform: rotate(0) translate(0px, 0px);
            transition: transform 0.2s linear;
          }
          &.open {
            > div {
              &:first-child {
                transform: rotate(45deg) translate(3px, 2px);
                transition: transform 0.2s linear;
              }
              &:last-child {
                transform: rotate(-45deg) translate(2px, -1px);
                transition: transform 0.2s linear;
              }
            }
          }
        }
      }
    }
    .nav-oval {
      background: linear-gradient(231.35deg, $pink-d1 0%, $pink 100%);
      .nav-items {
        height: 0;
        overflow: hidden;
        &.show {
          height: auto;
        }
      }
    }
    .active {
      @include media-breakpoint-down(md) {
        .nav-oval {
          background: linear-gradient(231.35deg, $pink-d1 0%, $pink 100%);
          height: 100vh;
          left: 0;
          position: fixed;
          top: 0;
          width: 100vw;
          .nav-items {
            top: 40vh !important;
            .menuitem {
              padding: 0;
              &:last-child {
                margin-top: 40px;
              }
              a {
                color: $white;
                font-size: 28px;
                line-height: 28px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
/* Scale canvas with resize attribute to full size */
canvas[resize] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  @include media-breakpoint-down(md) {
    height: 50vh;
  }
}
.page-header {
  @include fluid-type(414px, 1920px, 18px, 20px);
  margin-bottom: 45px !important;
  max-height: 60px;
  color: $grey-d2;
  font-weight: 600;
  @include media-breakpoint-down(md) {
    max-height: 34px;
  }
  span {
    color: $pink;
  }
}
.page-header-row {
  & + .paragraph-svg-block {
    @include media-breakpoint-down(md) {
      .svg {
        display: none;
      }
    }
  }
}
