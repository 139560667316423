.price-list-block {
  margin-bottom: 60px;
  .price-title {
    @include line-title;
  }
  .table {
    width: 100%;
    display: table;
    .table-row.hidden {
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all ease-in-out 0.4s;
      &.show {
        display: flex;
        height: 44px !important;
        opacity: 1;
        transition: all ease-in-out 0.4s;
        width: 100%;
        .inner-div {
          text-align: right;
          @include media-breakpoint-down(md) {
            margin-left: 80px !important;
          }
        }
      }
      .th {
        border-top: none;
        font-size: 14px;
        line-height: 44px;
        font-weight: 500;
      }
    }
    .table-row {
      display: flex;
      height: 44px;
      transition: all ease-in-out 0.4s;
      width: 100%;
      &.hide {
        height: 0 !important;
        overflow: hidden !important;
        opacity: 0;
        transition: all ease-in-out 0.4s;
      }
      &.show {
        .inner-div {
          @include media-breakpoint-down(md) {
            margin-left: 80px !important;
          }
        }
      }
      .td {
        line-height: 44px;
        border-top: 1px solid $grey-d3-25;
        display: table-cell;
        @include fluid-type(414px, 1920px, 14px, 20px);
        white-space: nowrap;
        width: 100%;
        @include media-breakpoint-down(md) {
          &.from {
            max-width: 120px;
            span {
              display: inline;
            }
          }
          &.fixed,
          &.percentage {
            padding-right: 12px;
            @include media-breakpoint-down(md) {
              padding-right: 8px;
            }
            .inner-div {
              margin-left: -18px;
            }
          }
        }
        &.price {
          max-width: 120px;
          @include media-breakpoint-down(md) {
            max-width: 70px;
          }
        }
        &:nth-child(n + 2) {
          text-align: right;
        }
        &:last-child {
          font-weight: 600;
        }
      }
    }
  }
  .full-price-list {
    font-weight: 600;
    font-size: 18px;
    color: $grey-d2;
    border: none;
    border-bottom: 1px solid $pink;
    background-color: transparent;
    position: relative;
    z-index: 10;
    &:focus {
      outline: none;
    }
    &.close-list {
      border: none;
      &::after {
        content: "Close full price list";
        position: absolute;
        font-weight: 600;
        font-size: 18px;
        color: $grey-d2;
        border-bottom: 1px solid $pink;
        white-space: nowrap;
        width: auto;
        right: 0;
        padding-bottom: 4px;
        top: 0;
      }
      span {
        display: none;
      }
    }
  }
}

.calculator-block {
  margin: 70px 0;
  position: relative;
  z-index: 10;
  h3 {
    @include fluid-type(414px, 1920px, 28px, 34px);
    line-height: calc(36px + (43 - 36) * ((100vw - 414px) / (1920 - 414)));
  }
  .text {
    color: $pink;
    font-size: 30px;
    font-family: "Caveat", cursive;
  }
  .price {
    text-align: right;
  }
  label {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    color: $grey-d3;
    margin-bottom: 11px;
    width: 100%;
  }
  input {
    border: 1px solid $red;
    height: 59px;
    padding-left: 10px;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    color: $grey-d3;
    width: 100%;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
    &:focus {
      outline: none;
    }
    &::selection {
      background-color: $pink-l1;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .sum {
    @include fluid-type(414px, 1920px, 34px, 40px);
    font-weight: 600;
    color: $grey-d2;
    border-bottom: 2px solid $pink-d2;
    height: 56px;
    .result {
      border-bottom: 2px solid $pink-d2;
      font-size: 40px;
      margin-bottom: -2px;
      &.wow-font {
        font-size: 50px;
        font-family: "Caveat", cursive;
        margin: -6px 6px 0 0;
        border-bottom: none;
      }
    }
  }
  .disclaimer,
  .disclaimer-fixed,
  .wow,
  button p {
    font-size: 18px !important;
    line-height: 23px;
    color: $grey-d3;
  }
  .disclaimer {
    margin-top: 0;
    &.drag-up {
      margin-top: -70px;
    }
  }
  .too-high-val {
    margin-top: 20px;
    h4 {
      color: $grey-d2;
      display: inline;
    }
  }
  .we-contact {
    border-bottom: none;
  }
  .we-contact,
  .we-contact-you {
    border: none;
    background-color: transparent;
    font-size: 20px;
    line-height: 22px;
    display: inline;
    color: $grey-d2;
    text-decoration: none;
  }
  .we-contact-you,
  .contact-us {
    cursor: pointer;
    font-size: 20px;
    line-height: 22px;
    display: inline;
    font-weight: 500;
    color: $grey-d2;
    text-decoration: none;
    border-bottom: 2px solid $pink;
    text-align: right;
  }
  .email-box {
    visibility: hidden;
    input.client-email {
      border: none;
    }
    button {
      border: none;
      background-color: transparent;
    }
    &.show {
      visibility: visible;
      padding-top: 40px;
      input.client-email {
        border-bottom: 2px solid $pink !important;
        &::placeholder {
          @include fluid-type(414px, 1920px, 20px, 24px);
          font-weight: 400;
        }
      }
      button.submit {
        font-weight: 500;
        @include fluid-type(414px, 1920px, 20px, 24px);
        padding: 8px 30px;
        position: absolute;
        right: 0;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
