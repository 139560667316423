.paragraph-svg-block {
  &.faq-page {
    // margin-bottom: 60px;
    .svg {
      @include media-breakpoint-up(md) {
        margin-top: -40px;
      }
      @include media-breakpoint-down(md) {
        order: 2 !important;
        svg {
          max-height: 200px;
        }
      }
    }
    .paragraph-col {
      @include media-breakpoint-up(md) {
        padding-right: 40px;
      }
      .paragraph-title {
        h2,
        h3 {
          font-weight: 600 !important;
        }
      }
    }
  }
  .svg-left {
    .desktop {
      justify-content: flex-end;
    }
  }
  .svg-right {
    .text {
      font-family: "Caveat", cursive;
      font-size: 30px;
      margin-top: 20px;
      @include media-breakpoint-down(md) {
        margin-top: -20px;
      }
    }
    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
  }
  @include media-breakpoint-down(md) {
    .desktop,
    .tablet,
    .mobile {
      margin: 0 auto;
      text-align: center;
    }
    &:nth-child(odd) {
      .svg-right {
        order: 1;
      }
      .paragraph-col {
        order: 2;
      }
    }
    .svg-right {
      order: 1;
      .tablet,
      .mobile {
        &::before {
          right: unset !important;
          top: 0 !important;
          width: 100vw !important;
          left: 0;
        }
        img {
          width: 139px;
          float: left;
        }
      }
    }
  }

  /********** SVG ANIMATION **********/
  svg {
    @include media-breakpoint-down(lg) {
      margin: 0 auto;
      height: 100%;
      max-height: 500px;
    }
  }
  .award-badge{
    &.render{
      .black-oval{
        fill: $yellow-l3;
        transition: fill .3s linear;
      }
      .white-oval{
        fill: transparent;
        transition: fill .3s linear;
      }
    }
  }
  .circle-to-heart {
    width: 188px;
    height: 100%;
    overflow: visible;
    .st3 {
      fill: $grey-d2 !important;
    }
    g#Heart {
      opacity: 0;
      transition: opacity linear 0.5s;
    }
    g#Circle {
      opacity: 1;
      transition: opacity linear 0.5s;
    }
    g#Pen {
      transform: translate(29%, 10px);
      transition: transform linear 0.5s;
    }
    &.render {
      g#Circle {
        opacity: 0;
      }
      g#Heart {
        opacity: 1;
      }
      g#Pen {
        transform: translate(29%, 38%);
      }
    }
  }
  .paint-brush {
    overflow: visible;
    height: 100%;
    @include media-breakpoint-down(md) {
      z-index: 10;
      transform: translate(0, -48px) rotate(-90deg);
      margin: 0;
      rect {
        fill: $yellow-l2;
      }
    }
  }
  .puzzles {
    width: 392px;
    height: 470px;
    @include media-breakpoint-down(md) {
      width: 300px;
    }
    g#pink {
      transform: translate(0%, -21%);
      @include media-breakpoint-down(lg) {
        transform: translate(0%, -17%);
      }
    }
    &.render {
      g#pink {
        transform: translate(0%, 0%);
        transition: transform ease-in-out 0.5s 0.6s;
      }
    }
  }
  .cloud-sun {
    margin-top: -100px;
    height: 100%;
    width: 500px;
    overflow: visible;
    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }
    @include media-breakpoint-down(md) {
      width: 300px;
    }
    circle#Sun,
    circle#Hallow {
      transform: translate(-11%, 14%);
    }
    circle#Hallow {
      opacity: 0;
    }
    &.render {
      circle#Sun,
      circle#Hallow {
        transform: translate(11%, -14%);
        opacity: 1;
        transition: transform ease-in-out 1s 0.6s, opacity ease 0.2s 0.8s;
      }
    }
  }
  .red-carpet {
    width: 400px;
    height: 100%;
    margin-top: -50px;
    @include media-breakpoint-down(md) {
      width: 200px;
      margin-top: 0;
      image {
        display: none;
      }
    }
    #open {
      display: block !important;
      .st8 {
        transform: rotateY(48deg);
        opacity: 0;
      }
    }
    .st5 {
      fill: $white !important;
      stroke: $grey-d2 !important;
    }
    .chain-open {
      opacity: 0;
    }
    &.render {
      #Carpet_1_ {
        fill: #ebebeb;
      }
      #Carpet {
        transition: all linear 0.5s 1s, fill linear 0.1s 1.5s;
        animation: 1s down-enter;
        animation: 1s down-leave;
        animation-delay: 1s;
        fill: $pink;
      }
      @keyframes down-enter {
        0% {
          clip-path: inset(0);
        }
        100% {
          clip-path: inset(0 0 100% 0);
          fill: $pink;
        }
      }
      @keyframes down-leave {
        0% {
          clip-path: inset(100% 0 0 0);
        }
        100% {
          clip-path: inset(0);
          fill: $pink;
        }
      }
      .chain-closed {
        transform: rotateY(90deg);
        transform-origin: 20px;
        opacity: 0;
        transition: transform linear 0.5s 1s, opacity linear 0.5s 1.5s;
      }
      image.closed-shadow {
        transform: rotateY(86deg) translate(-126 -128);
        opacity: 0;
        transition: all ease 0.3s 1s;
      }
      .st5 {
        opacity: 0;
        transform: rotateY(90deg);
        transition: transform linear 0.5s 1s, opacity linear 0.5s 1.5s;
        &:nth-child(odd) {
          opacity: 0;
          transform: none;
        }
      }
      g#open {
        #Path-14_1_ {
          transform: translate(-10px, 10px);
        }
        .chain-open {
          opacity: 1;
          transition: opacity linear 0.5s 1.3s;
        }
        .st8 {
          opacity: 1;
          transition: opacity linear 0.5s 1.3s;
          fill: $white !important;
          stroke: $grey-d2 !important;
        }
      }
    }
  }
  .messages {
    width: 380px;
    height: 100%;
    overflow: visible !important;
    @include media-breakpoint-down(md) {
      width: 300px;
      image {
        display: none;
      }
    }
    g#activ_bubbles {
      opacity: 1;
    }
    g#base_bubbles {
      opacity: 0;
    }
    #Pink_activ_bubble {
      transform: translate(10px, -100px);
      opacity: 0;
    }
    path[id*="White_line_"] {
      transform: translate(10px, -100px);
      opacity: 0;
    }
    #White_activ_bubble {
      transform: translate(100px, 10px);
      opacity: 0;
    }
    path[id*="Black_line_"] {
      transform: translate(100px, 10px);
      opacity: 0;
    }
    &.render {
      g#activ_bubbles {
        opacity: 1;
        transition: opacity linear 0.7s;
        #Pink_activ_bubble {
          transform: translate(0, 0);
          opacity: 1;
          transition: transform ease-in 0.7s 1s, opacity linear 0.3s 1s;
        }
        path[id*="White_line_"] {
          opacity: 1;
          transform: translate(0, 0);
          transition: transform ease-in 0.7s 1s, opacity linear 0.3s 1s;
        }
        #White_activ_bubble {
          opacity: 1;
          transform: translate(0, 0);
          transition: transform ease-in 0.7s 1s, opacity linear 0.3s 1s;
        }
        path[id*="Black_line_"] {
          opacity: 1;
          transform: translate(0, 0);
          transition: transform ease-in 0.7s 1s, opacity linear 0.3s 1s;
        }
      }
    }
  }
  .rocket {
    width: 400px;
    max-height: 690px;
    position: absolute;
    overflow: visible;
    @include media-breakpoint-down(lg) {
      position: relative;
      max-height: 380px;
      margin-top: -100px;
    }
    g#Rocket {
      transform: translateY(16.6%);
      @include media-breakpoint-down(lg) {
        transform: translateY(44%);
      }
      g#Lines_long {
        opacity: 0;
      }
    }
    .st8 {
      fill: #f6f6f6 !important;
      stroke: $white !important;
    }
    #Oval_h,
    #Oval_g,
    #Oval_f,
    #Oval_e,
    #Oval_d,
    #Oval_c,
    #Oval_b,
    #Oval_a {
      opacity: 0;
      transform: translateY(-45%);
      @include media-breakpoint-down(lg) {
        transform: translateY(-12%);
      }
    }
    .st1 {
      stroke: none;
    }
    &.rocket-launch {
      g#Rocket {
        transform: translateY(-200px);
        transition: cubic-bezier(0, 0.48, 0.65, 1.01) 1s 0.8s;
        @include media-breakpoint-down(lg) {
          transform: translateY(60px);
        }
        g#Lines_long {
          opacity: 1;
          transition: opacity linear 0.3s 0.8s;
        }
      }
      #Oval_h,
      #Oval_g,
      #Oval_f,
      #Oval_e,
      #Oval_d,
      #Oval_c,
      #Oval_b,
      #Oval_a {
        animation: fade 3s linear;
        animation-delay: 0.8s;
      }
      #Oval_f {
        transition: opacity ease-in-out 0.7s 1.3s;
      }
      #Oval_c,
      #Oval_g {
        transition: opacity ease-in-out 0.9s 1.5s;
      }
      #Oval_h {
        transition: opacity ease-in-out 0.9s 0.9s;
      }
      #Oval_b {
        transition: opacity ease-in-out 1s 1.2s;
      }
      #Oval_a {
        transition: opacity ease-in-out 1s 1.7s;
      }
      #Oval_d,
      #Oval_e {
        transition: opacity ease-in-out 0.6s 1s;
      }
      @keyframes fade {
        0%,
        100% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
      }
    }
  }
}

.yellow {
  .svg-right {
    @include media-breakpoint-down(lg) {
      position: absolute;
    }
  }
  .paragraph-svg-block {
    .paragraph-col {
      @include media-breakpoint-down(md) {
        order: 2;
        margin-top: 140px;
      }
    }
  }
}
