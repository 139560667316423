.airserve {
  h2 {
    @include fluid-type(414px, 1920px, 42px, 54px);
    line-height: calc(42px + (48 - 42) * ((100vw - 414px) / (1920 - 414)));
    font-weight: 600;
  }
  h3 {
    @include fluid-type(414px, 1920px, 34px, 40px);
    line-height: calc(34px + (40 - 34) * ((100vw - 414px) / (1920 - 414)));
    font-weight: 600;
    @include media-breakpoint-down(sm) {
      line-height: 24px;
    }
  }
  h4 {
    @include fluid-type(414px, 1920px, 20px, 24px);
    line-height: calc(24px + (28 - 24) * ((100vw - 414px) / (1920 - 414)));
    font-weight: 400;
    color: $grey-d3 !important;
    margin-top: 17px;
    margin-bottom: 34px;
  }
  p {
    @include fluid-type(414px, 1920px, 18px, 20px);
    line-height: calc(22px + (24 - 22) * ((100vw - 414px) / (1920 - 414)));
    color: $grey-d3 !important;
    margin: 14px 0;
    @media (min-width: 1920px) {
      font-size: 20px;
    }
    @media (max-width: 414px) {
      font-size: 18px;
    }
    a {
      @include fluid-type(414px, 1920px, 18px, 20px);
      font-weight: 600;
      border-bottom: 1px solid $pink;
      color: $grey-d2;
      text-decoration: none;
      line-height: calc(26px + (28 - 26) * ((100vw - 414px) / (1920 - 414)));
    }
  }
}
