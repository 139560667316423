@mixin line-title {
  @include fluid-type(414px, 1920px, 28px, 34px);
  line-height: calc(32px + (36 - 32) * ((100vw - 414px) / (1920 - 414)));
  position: relative;
  &::before {
    content: "";
    background-color: $pink;
    height: 2px;
    left: -80px;
    position: absolute;
    top: 20px;
    width: 50px;
    @include media-breakpoint-down(md) {
      content: none;
    }
  }
}
