.form-page {
    .side-col {
        background: linear-gradient(202.75deg, $pink 0%, $pink-d1 100%);
        padding-top: 112px;
        padding-bottom: 60px;
        min-height: 100vh;
        margin-bottom: 0 !important;

        @include media-breakpoint-down(sm) {
            position: absolute;
            max-height: 90px;
            min-height: unset;
            padding-top: 0;

            svg {
                max-width: 66px;
                height: 90px;
                margin-left: 30px;
            }
        }

        img {
            max-width: 187px;
        }

        span {
            color: $white;
            font-size: 18px;
        }
    }

    .form-container {
        @include media-breakpoint-down(md) {
            padding-top: 150px;
            padding-bottom: 50px;
        }

        p.font-caveat {
            color: $pink !important;
            font-size: 34px;
            font-weight: 700;
        }

        .pretitle {
            font-weight: 300;
            color: $grey-d3 !important;
        }

        .title {
            margin-top: 40px;
        }

        .subtitle {
            font-size: 24px;
            font-weight: 300;
            color: $grey-d3 !important;
        }

        section {
            margin-top: 70px;

            form {
                .field-container {
                    margin-bottom: 60px;
                    width: 100%;
                    border-bottom: 1px solid $grey-d3;

                    &.error {
                        border-bottom: 1px solid #E6637F7F;
                    }

                    &.radio {
                        border-bottom: none;
                        width: 100%;
                        min-height: 20px;

                        &>div {
                            display: flex;
                            gap: 20px;
                            @include media-breakpoint-down(lg) {
                                gap: 12px;
                            }
                            @include media-breakpoint-down(md) {
                                gap: 8px;
                            }
                        }

                        &>label {
                            color: #525252;
                            font-family: "futura-pt";
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0;
                            line-height: 21px;
                            display: block;
                            margin-bottom: 12px;
                        }

                        #id_account_type {
                            display: flex;
                            gap: 20px;
                            @include media-breakpoint-down(md) {
                                gap: 15px;
                            }

                            input {
                                margin-right: 8px;
                            }
                            label {
                                b {
                                    span {
                                        height: 4px;
                                        border-radius: 50%;
                                        background-color: $pink_d1;
                                        display: inline-block;
                                        margin-left: 2px;
                                    }
                                }
                            }
                        }

                        div.form-help {
                            width: 18px;
                            height: 18px;
                            position: relative;
                            display: inline-block;
                            top: 5px;
                            background-image: url('data:image/svg+xml,%3Csvg width="18" height="18" xmlns="http://www.w3.org/2000/svg"%3E%3Cg id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"%3E%3Cg id="signup-2-B" transform="translate(-1228 -424)"%3E%3Cg id="%3F" transform="translate(1228 424)"%3E%3Cg id="Group-10" fill="%23B73966"%3E%3Ccircle id="Oval-Copy-2" cx="9" cy="9" r="9"/%3E%3C/g%3E%3Cpath d="M3.253 6.466V5.05a2.678 2.678 0 0 0 1.54-.756c.458-.459.688-1.013.688-1.742 0-.904-.324-1.498-.702-1.863C4.212.135 3.51 0 2.808 0 1.674 0 1.04.378.675.756.297 1.161 0 1.876 0 2.579h1.755c0-.473.149-.743.297-.878.081-.081.297-.243.688-.243.338 0 .554.121.702.27.162.162.284.419.284.783 0 .365-.148.688-.351.905-.284.283-.743.458-1.269.458-.189 0-.364-.013-.526-.054v2.647h1.673Zm-1.89 1.89c0 .595.46 1.053 1.054 1.053.594 0 1.053-.459 1.053-1.053 0-.593-.46-1.053-1.054-1.053-.593 0-1.052.46-1.052 1.054Z" transform="translate(6.192 4.293)" fill="%23FFF" fill-rule="nonzero" id="Shape"/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
                            cursor: pointer;


                            &:hover,
                            &:active {
                                &>div {
                                    opacity: 1;
                                }
                            }

                            &>div {
                                position: absolute;
                                width: 300px;
                                max-height: 300px;
                                font-size: 16px;
                                right: -10px;
                                bottom: 30px;
                                background-color: $grey;
                                padding: 10px;
                                border-radius: 10px;
                                color: $white;
                                opacity: 0;
                                transition: opacity 0.3s linear;

                                &::after {
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-left: 10px solid transparent;
                                    border-right: 10px solid transparent;
                                    border-top: 10px solid $grey;
                                    position: absolute;
                                    right: 10px;
                                    bottom: -10px;
                                }
                            }
                        }

                        ul {
                            li {
                                float: left;
                                cursor: pointer;

                                input {
                                    display: none;

                                    &+label {
                                        padding-left: 25px;
                                        margin-right: 30px;
                                        position: relative;
                                        transition: none;
                                        cursor: pointer;

                                        span {
                                            width: 4px;
                                            height: 4px;
                                            border-radius: 50%;
                                            background-color: $pink;
                                            display: inline-block;
                                        }


                                        &:before {
                                            content: '';
                                            border: 1px solid $grey;
                                            border-radius: 50%;
                                            width: 17px;
                                            height: 17px;
                                            position: absolute;
                                            left: 0;
                                            top: 50%;
                                            transform: translateY(-50%);
                                        }
                                    }

                                    &:checked {
                                        &+label {
                                            &:after {
                                                content: '';
                                                border-radius: 50%;
                                                width: 9px;
                                                height: 9px;
                                                background-color: $pink;
                                                position: absolute;
                                                left: 4px;
                                                top: 50%;
                                                transform: translateY(-50%);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        input[type="radio"] {
                            accent-color: $pink;
                        }
                    }
                }

                input {
                    border: none;
                    border-radius: 0;
                    padding-bottom: 8px;

                    &:focus,
                    &:active {
                        outline: none;
                    }
                }

                input[type=submit] {
                    height: 60px;
                    width: 205px;
                    border-radius: 8px;
                    background-color: $green;
                    @include fluid-type(414px, 1920px, 20px, 24px);
                    font-weight: 500;
                    border-bottom: none;
                    color: $white;
                    margin-top: 20px;

                    &:hover {
                        background-color: lighten($green, 10%);
                        transition: background-color .3s linear;
                    }

                    &:disabled {
                        background-color: $grey-light;
                    }
                }

                .text,
                .email {
                    input {
                        width: 100%;
                    }

                    label {
                        position: absolute;
                        @include fluid-type(414px, 1920px, 20px, 24px);
                        color: $grey-d3;
                        top: 0;
                        transition: all .3s linear;

                        &.label-up {
                            font-size: 16px;
                            transition: all .3s linear;
                            top: -28px;
                        }
                    }
                }

                ul {
                    padding-left: 0;

                    li {
                        list-style: none;
                        position: relative;

                        &.field-id_wagtailcaptcha {
                            margin: 0;
                            border: none;
                        }
                    }
                }

                .workspace_placeholder {
                    position: relative;
                    display: inline-block;

                    &:after {
                        content: '.airserve.net';
                        position: absolute;
                        left: 100%;
                        color: $pink;
                        cursor: text;
                    }

                    &.right {
                        float: right;

                        &:after {
                            right: 0;
                            left: auto;
                        }
                    }
                }

                label span.error {
                    color: $pink;
                    padding-left: 5px;
                }

            }

            .thank-content {
                .dark {
                    margin-bottom: 130px;
                }

                p {
                    @include fluid-type(414px, 1920px, 20px, 24px);
                    line-height: 31px;
                }
            }

            .thank-btn {
                p {
                    font-weight: 400;
                }

                a {
                    font-weight: 600;
                    border-bottom: 1px solid $pink;
                    color: $grey-d2;
                    @include fluid-type(414px, 1920px, 20px, 24px);

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}