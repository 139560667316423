$white: #FFFFFF;

$grey-l1: #f6f6f6;
$grey-light: #666666;
$grey: #4A4A4A;
$grey-dark: #353535;
$grey-d2: #2A2A2A;
$grey-d3: #525252;
$grey-d3-25: rgba(82, 82, 82, 0.25);

$green: #28CA65;
$green-d1: #279A52;

$pink-l1: rgba(230, 99, 127, .15);
$pink: #E6637F;
$pink-d1: #B73966;
$pink-d2: #dc3545;

$yellow-l4: #EDC72E;
$yellow-l3: #F9D339;
$yellow-l2: #FADA5B;
$yellow-l1: #EDDB0B;
$yellow: #EDC72E;
