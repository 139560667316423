.signup-block {
  margin-top: 60px;
  @include media-breakpoint-down(sm) {
    justify-content: center;
    margin-top: 40px;
  }
  &.right-side {
    .text {
      margin-bottom: 40px;
    }
    .signup-button {
      @include media-breakpoint-down(md) {
        margin: 0 auto;
      }
    }
  }
  .text {
    font-weight: 600;
    @include fluid-type(414px, 1920px, 20px, 22px);
    line-height: 22px;
    @include media-breakpoint-up(xl) {
      padding-right: 25px;
    }
  }
  .signup-button {
    background-color: $green;
    border-radius: 8px;
    height: 60px;
    max-width: 205px;
    @include media-breakpoint-down(md) {
      height: 48px;
    }
    &:hover{
      background-color: $green-d1;
    }
    a {
      color: $white;
      font-weight: 600;
      text-decoration: none;
      @include fluid-type(414px, 1920px, 20px, 24px);
    }
  }
}
