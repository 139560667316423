footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    .footer-container{
        height: 143px;
        padding: 0 !important;
        @include media-breakpoint-down(md){
            padding: 20px !important;
            .bottom-container, .top-container{
                text-align: center;
                display: block !important;
            }
            .bottom-container{
                margin-top: -40px;
            }
        }
        .top-container, .bottom-container{
            margin-bottom: 0;
            align-items: center;
        }
        .name{
            svg{
                width: 117px !important;
            }
        }
    }
}

footer {
    background-color: $grey-d2;
    color: $white;
    &.show {
        .top-container, .middle-container, .bottom-container {
            transform: translateX(0%)!important;
            transition: .5s transform ease-in-out;
        }
    }
    h3 {
        color: $white;
    }
    div.footer-container{
        height: 143px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        @include media-breakpoint-down(md) {
            padding: 20px;
            .bottom-container, .top-container{
                text-align: center;
                display: block !important;
            }
            .bottom-container{
                margin-top: -40px;
            }
        }
        .top-container, .bottom-container {
            transition: .5s transform ease-in-out;
            margin-bottom: 0;
            align-items: center;
        }
        div.top-container{
            transform: translateX(-100%);
            .name {
                svg {
                    width: 117px;
                    path:nth-child(1) {
                        fill: $white;
                    }
                }
            }
        }
        div.bottom-container{
            transform: translateX(-100%);
            display: flex;
            .social {
                font-size: 18px;
            }
        }
    }
}
